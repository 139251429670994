import { put, call, select } from "redux-saga/effects";
import * as Eff from 'redux-saga/effects'
import { getStatusHistory, getSeverities, getMessageTypes, getIncidentById, getDataCenters, getIncidents, } from './statusHistory.api';
import { actions as statusHistoryActions, ActionTypes as statusHistoryActionTypes, DataCenterShape } from './statusHistory.index';
import { actions as uiActions } from '../ui/ui.index';
import { actions as errorActions } from '../errors/errors.index';
import { actions as alertActions } from '../alerts/alerts.index';
import { RootState } from "../../redux/reducers";

const takeEvery: any = Eff.takeEvery;
const valid_data_centers = ['eu', 'cn', 'usg', 'us2', 'eu2', 'concurgovprod', 'apj1'];
const japanese_services = ['Japan - e-Bunsho for Expense', 'Japan - e-Bunsho for Invoice', 'Japan - Japan Public Transportation (JPT)']

export function* fetchStatusHistory(action:any) {
  const dataCenter = action.payload;
  // Overwrite "us" dc as "us2" for getting status history
  const switchedDC = dataCenter === "us" ? "us2" : dataCenter;
  try {
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[action.payload]: true} }));
    const { profile: { services, company_id, tzOffset } } = yield select( (state:RootState) => state.authState ); 
    if (!valid_data_centers.includes(switchedDC)){
      return;
    }
    // @ts-ignore
    const response  = yield call(getStatusHistory, switchedDC, tzOffset, company_id);
    const dcServiceRows = response.data.data;

    // If using PCO, this will pull available services from user's profile. Else, it will include all services.
    let userServices = services.length > 0 ? services : Object.keys(dcServiceRows);
    // If user is in US2 or EU2 or APJ1, add Japanese services if they are not included already
    if (switchedDC === 'us2' || switchedDC === 'eu2' || switchedDC === 'apj1') {
      for (let i in japanese_services) {
        if (!userServices.includes(japanese_services[i])) {
          userServices.push(japanese_services[i])
        }
      }
    }
    yield put(statusHistoryActions.setRows(switchedDC, dcServiceRows, userServices));
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[switchedDC]: false} }));
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[switchedDC]: false} }));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchSeverities() {
  try {
    // @ts-ignore
    const response  = yield call(getSeverities);
    yield put(statusHistoryActions.setSeverities(response.data.severity_list));
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchMessageTypes(data:any) {
  try {
    // @ts-ignore
    const response  = yield call(getMessageTypes, data.payload);
    yield put(statusHistoryActions.setMessageTypes(response.data.P1.alert_types));
    yield put(statusHistoryActions.setOrderedServices(response.data.P1.services));
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchFreshData(action:any){
  // @ts-ignore
  const store = yield select( ( { uiState, authState, statusHistoryState }:RootState ) => ({
    pageHasFocus        : uiState.pageHasFocus,
    isDisconnected      : uiState.isDisconnected,
    loggedIn            : authState.loggedIn,
    profile_data_center : authState.profile.data_center,
    company_id          : authState.profile.company_id,
    status_history_dc   : statusHistoryState.activeDataCenter,
    dataCenters         : statusHistoryState.dataCenters
  }));
  // AUTO REFRESH LOGIC
  // if there is data that needs to be refreshed every minute, add the action call to this function
  try {
    if (store.pageHasFocus && !store.isDisconnected) {
      yield put(uiActions.setLastRefreshLoading(true));

      yield put(alertActions.requestDisruptionBanner());

      let profile_dc = store.profile_data_center;
      if (profile_dc == 'us'){
        profile_dc = 'us2';
      }
      // add auto-refresh calls for PCO only data here
      if (store.loggedIn && store.company_id && store.profile_data_center && store.dataCenters.length > 0) {
        // service advisories
        const serviceAdvisoryDC = store.dataCenters.find( (el:DataCenterShape) => el.profile_services_name === profile_dc)
        if (serviceAdvisoryDC)  {
          yield put(alertActions.requestAllActiveServiceAdvisories(serviceAdvisoryDC.legacy_name));
        }

        // p2 data
        if (store.status_history_dc){
          yield put(statusHistoryActions.requestP2Incidents(store.status_history_dc));
        }
      }
      // AUTO REFRESH THE CURRENT DC TAB, NOT EVERY SINGLE DC. IF WE REFRESH EVERY DC, WE WILL TAKE A PERFORMANCE HIT
      yield put(statusHistoryActions.requestStatusHistory(action.payload));
      yield put(uiActions.setLastRefresh());

      yield put(uiActions.setLastRefreshLoading(false));
    }

  } catch (error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));
    yield put(uiActions.setLastRefreshLoading(false));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}
export function* fetchIncidentById(data:any) {
  try {
    yield put(uiActions.setComponentLoading({ 'SingleIncident': true }));
    // @ts-ignore
    const response  = yield call(getIncidentById, data.payload);
    yield put(statusHistoryActions.setIncidentById(response.data.incidents));
    yield put(uiActions.setComponentLoading({ 'SingleIncident': false }));
  }
  catch(error) {
    // handle ui effects
    yield put(uiActions.setComponentLoading({ 'SingleIncident': false }));
    yield put(statusHistoryActions.setStatusHistoryError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchDataCenters() {
  try {
    // @ts-ignore
    const response  = yield call(getDataCenters);
    const dc_names = response.data.data;
    if (response.data && dc_names){
      // this removes north america
      // TODO: add active to data center table and remove this
      dc_names.splice(0, 1);
      yield put(statusHistoryActions.setDataCenters(dc_names));
    }
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));

    // // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

export function* fetchP2Incidents(action:any) {
  const dataCenter = action.payload;
  // Overwrite "us" dc as "us2" for getting status history
  const switchedDC = dataCenter === "us" ? "us2" : dataCenter;
  try {
    const { profile: { company_id } } = yield select( (state:RootState) => state.authState );
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[switchedDC]: true} }));
    // @ts-ignore
    const response  = yield call(getIncidents, 'P2', company_id ?? null, 'y');
    const incidents = response.data.incidents;
    yield put(statusHistoryActions.setP2Incidents(switchedDC, incidents));
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[switchedDC]: false} }));
  }
  catch(error) {
    // handle ui effects
    yield put(statusHistoryActions.setStatusHistoryError(true));
    yield put(uiActions.setComponentLoading({ 'StatusHistory': {[switchedDC]: false} }));

    // send error report
    // @ts-ignore
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

// watcher saga
export default function statusHistorySagas() {
    return [
      takeEvery(statusHistoryActionTypes.REQUEST_STATUS_HISTORY, fetchStatusHistory),
      takeEvery(statusHistoryActionTypes.REQUEST_SEVERITIES, fetchSeverities),
      takeEvery(statusHistoryActionTypes.REQUEST_MESSAGE_TYPES, fetchMessageTypes),
      takeEvery(statusHistoryActionTypes.REFRESH_DATA, fetchFreshData),
      takeEvery(statusHistoryActionTypes.REQUEST_INCIDENT_BY_ID, fetchIncidentById),
      takeEvery(statusHistoryActionTypes.REQUEST_DATA_CENTERS, fetchDataCenters),
      takeEvery(statusHistoryActionTypes.REQUEST_P2_INCIDENTS, fetchP2Incidents),
    ]
}
